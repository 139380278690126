import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { userHasAccess } from "@impact-theory/app-auth";
import MeshARDashboard from "../components/MeshARDashboard";
import MTADashBoard from "../components/MTADashboard";

function MTADashboard() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess();
      setLoggedIn(result);
    }
    run();
  }, []);

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    );

  return (
    <Layout>
      <Heading marginBottom="2rem">Mesh Type Assessment</Heading>
      <Tabs>
        <TabList>
          <Tab>MTA Dashboard</Tab>
          <Tab>Mesh AR</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MTADashBoard />
          </TabPanel>
          <TabPanel>
            <MeshARDashboard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}

export default MTADashboard;

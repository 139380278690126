/* eslint-disable no-console */
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Img,
  useToast,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Spinner,
  Text,
} from "@chakra-ui/react";

import AirtableBase from "./AirtableBase";
import BarChart from "./BarChart";

const MeshARDashboard = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    theme: "",
    startDate: "",
    endDate: "",
  });

  const [loading, setLoading] = useState(true);
  const [soulNumber, setTotalSoul] = useState(0);
  const [avatarNumber, setTotalAvatar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [anonymousSummary, setAnonymousSummary] = useState([]);
  const [walletNumber, setNumberOfWallet] = useState(0);

  useEffect(() => {
    getNumberOfMeshType();
    getNumberOfToken();
    getNumberOfWallet();
    getAnonymousMeshType();
  }, []);
  const getNumberOfToken = async () => {
    setLoading(true);
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MESH_API_SERVER}/api/number-of-tokens`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            setTotalSoul(response.data.Soul);
            setTotalAvatar(response.data.Avatar);
            setLoading(false);
          }
        }
      });
  };

  const getNumberOfMeshType = async () => {
    setLoading(true);
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MESH_API_SERVER}/api/number-of-mesh-type`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            const dataObject = response.data;
            const dataArray = Object.keys(dataObject).map((key) => ({
              name: key,
              value: dataObject[key],
            }));

            setSummary(dataArray);
            setLoading(false);
          }
        }
      });
  };
  const getNumberOfWallet = async () => {
    setLoading(true);
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MESH_API_SERVER}/api/number-of-wallets`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            setNumberOfWallet(response.data);
            setLoading(false);
          }
        }
      });
  };
  const getAnonymousMeshType = async () => {
    setLoading(true);
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MESH_API_SERVER}/api/number-of-anonymous-mesh-type`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            const dataObject = response.data;
            const dataArray = Object.keys(dataObject).map((key) => ({
              name: key,
              value: dataObject[key],
            }));

            setAnonymousSummary(dataArray);
            setLoading(false);
          }
        }
      });
  };

  return (
    <Box minH={"50vh"}>
      {loading ? (
        <Box
          w="100%"
          justifyContent="center"
          alignItems="center"
          alignContent={"center"}
          display="block"
        >
          <Text textAlign="center">
            Hold your horses, we are loading the data...
          </Text>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            display="block"
            margin="40px auto"
          />
        </Box>
      ) : (
        soulNumber && (
          <>
            <StatGroup
              border="1px solid #cccccc"
              borderRadius="md"
              padding="5"
              display="inline-block"
              margin="40px"
              bgColor="beige"
            >
              <Stat>
                <StatLabel fontSize="1.5em">Total Soul</StatLabel>
                <StatNumber fontSize="4.0em">{soulNumber}</StatNumber>
              </Stat>
            </StatGroup>
            <StatGroup
              border="1px solid #cccccc"
              borderRadius="md"
              padding="5"
              display="inline-block"
              margin="40px"
              bgColor="beige"
            >
              <Stat>
                <StatLabel fontSize="1.5em">Total Avatar</StatLabel>
                <StatNumber fontSize="4.0em">{avatarNumber}</StatNumber>
              </Stat>
            </StatGroup>

            <StatGroup
              border="1px solid #cccccc"
              borderRadius="md"
              padding="5"
              display="inline-block"
              margin="40px"
              bgColor="beige"
            >
              <Stat>
                <StatLabel fontSize="1.5em">Total Wallet</StatLabel>
                <StatNumber fontSize="4.0em">{walletNumber}</StatNumber>
              </Stat>
            </StatGroup>

            {anonymousSummary && summary && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div>
                  <h1>Wallet</h1>
                  <BarChart data={summary} />
                </div>

                <div className="App">
                  <h1>Anonymous</h1>
                  <BarChart data={anonymousSummary} />
                </div>
              </div>
            )}
          </>
        )
      )}
    </Box>
  );
};

export default MeshARDashboard;

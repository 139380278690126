/* eslint-disable no-console */
import { useEffect, useState } from "react";

import {
  Box,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Img,
  useToast,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Spinner,
  Text,
} from "@chakra-ui/react";

import axios from "axios";

const AvatarArenaDashboard = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    theme: "",
    startDate: "",
    endDate: "",
  });

  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState([]);
  const [participants, setParticipants] = useState(0);
  const [battles, setBattles] = useState(0);

  useEffect(() => {
    getLeaderboard();
    console.log("useEffect");
  }, []);

  const getLeaderboard = async () => {
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_SERVER}/api/leaderboard/getLeaderboard`
      )
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoading(false);
        setLeaders(response.data.data.top);
        setParticipants(response.data.data.participantsCount);
        setBattles(response.data.data.roundsCount);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <Box minH={"50vh"}>
      {loading ? (
        <Box
          w="100%"
          justifyContent="center"
          alignItems="center"
          alignContent={"center"}
          display="block"
        >
          <Text textAlign="center">
            Hold your horses, we are loading the data...
          </Text>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            display="block"
            margin="40px auto"
          />
        </Box>
      ) : (
        leaders && (
          <>
            <StatGroup border="1px solid #cccccc" borderRadius="md" padding="5">
              <Stat>
                <StatLabel>Total Avatars</StatLabel>
                <StatNumber>{participants}</StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Total Battles</StatLabel>
                <StatNumber>{battles}</StatNumber>
              </Stat>
            </StatGroup>
            <TableContainer>
              <Table variant="simple">
                <TableCaption>Leaders</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Rank</Th>
                    <Th>Token ID</Th>
                    <Th>Avatar Image</Th>
                    <Th isNumeric>Votes</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {leaders &&
                    leaders.map((leader, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{leader.rank}</Td>
                          <Td>{leader.avatarId}</Td>
                          <Td>
                            <Img
                              src={leader.metadata.image}
                              width="100px"
                              height="100px"
                            />
                          </Td>
                          <Td isNumeric>{leader.voteCount}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )
      )}
    </Box>
  );
};

export default AvatarArenaDashboard;

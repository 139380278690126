import lifecycles from "./impact-theory-app-reports";

export const reportsSubMenu = [
  {
    label: "Avatar Arena",
    subLabel: "Avatar Arena Dashboard",
    href: "/reports/dashboard",
  },
  {
    label: "Kyzen Marketplace",
    subLabel: "Kyzen Marketplace Reports",
    href: "/reports/marketplace-dashboard",
  },
  {
    label: "Mesh Type Assessment",
    subLabel: "Mesh Type Assessment Reports",
    href: "/reports/mesh-dashboard",
  },
];

export const { bootstrap, mount, unmount } = lifecycles;

import * as d3 from "d3";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Heading, Box } from "@chakra-ui/react";

function StatCountDaily() {
  useEffect(() => {
    getStatCountByCollection();
  }, []);

  const getStatCountByCollection = async () => {
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MARKETPLACE_API_SERVER}/api/v1/imx/stat/count/daily/orderBy/type`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            let arr = [];
            var parseDate = d3.timeParse("%Y-%m-%d");

            response.data.forEach((el) => {
              arr.push({
                date: parseDate(el.date),
                type: "buy",
                count: el.buy,
              });
              arr.push({
                date: parseDate(el.date),
                type: "list",
                count: el.list,
              });
            });
            renderChart(arr);
          }
        }
      });
  };

  const renderChart = (data) => {
    const margin = { top: 10, right: 30, bottom: 30, left: 60 },
      width = 460 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select("#line-chart")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //Read the data
    // d3.csv(
    //   "https://raw.githubusercontent.com/holtzy/data_to_viz/master/Example_dataset/5_OneCatSevNumOrdered.csv"
    // ).then(function (data) {
    // group the data: I want to draw one line per group
    const sumstat = d3.group(data, (d) => d.type); // nest function allows to group the calculation per level of a factor
    // Add X axis --> it is a date format

    const x = d3
      .scaleLinear()
      .domain(
        d3.extent(data, function (d) {
          return d.date;
        })
      )
      .range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).ticks(5).tickFormat(d3.timeFormat("%m.%d")));
    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d) {
          return +d.count;
        }),
      ])
      .range([height, 0]);
    svg.append("g").call(d3.axisLeft(y).ticks(1));

    // color palette
    const color = d3.scaleOrdinal().range(["#e41a1c", "#377eb8"]);

    // Draw the line
    svg
      .selectAll(".line")
      .data(sumstat)
      .join("path")
      .attr("fill", "none")
      .attr("stroke", function (d) {
        return color(d[0]);
      })
      .attr("stroke-width", 1.5)
      .attr("d", function (d) {
        return d3
          .line()
          .x(function (d) {
            return x(d.date);
          })
          .y(function (d) {
            return y(+d.count);
          })(d[1]);
      });
    // });
  };

  return (
    <Box>
      <Heading as="h3" size="lg">
        Daily Transactions
      </Heading>
      <div id="line-chart"></div>
    </Box>
  );
}

export default StatCountDaily;

import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import {
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Flex,
  Box,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { userHasAccess } from "@impact-theory/app-auth";
import StatCountDaily from "../components/StatCountDaily";
import TransactionsByType from "../components/TransactionsByType";
import TransactionsByCollection from "../components/TransactionsByCollection";

function MarketplaceDashboard() {
  const [loggedIn, setLoggedIn] = useState(false);
  //   useEffect(() => {
  //   }, [lineData]);

  return (
    <Layout>
      <Heading marginBottom="2rem">Kyzen Marketplace Dashboard</Heading>
      {/* <div id="line-chart"></div> */}

      <TransactionsByType></TransactionsByType>

      <TransactionsByCollection></TransactionsByCollection>

      <StatCountDaily></StatCountDaily>

      {/* <Tabs>
        <TabList>
          <Tab>Dashboard</Tab>
          <Tab>Voting By Wallet</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AvatarArenaDashboard />
          </TabPanel>
          <TabPanel>
            <AvatarArenaVotingDashboard />
          </TabPanel>
        </TabPanels>
      </Tabs> */}
    </Layout>
  );
}

export default MarketplaceDashboard;

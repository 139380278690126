/* eslint-disable no-console */
import { useEffect, useState } from "react";

import {
  Box,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Img,
  useToast,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Spinner,
  Text,
} from "@chakra-ui/react";

import AirtableBase from "./AirtableBase";

const MeshARDashboard = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    theme: "",
    startDate: "",
    endDate: "",
  });

  const [loading, setLoading] = useState(true);
  const [leaders, setLeaders] = useState([]);
  const [submissions, setSubmissions] = useState(0);
  const [pageViews, setPageViews] = useState(0);

  useEffect(() => {
    getTotalParticipants();
    getTotalPageViews();
  }, []);

  const getTotalParticipants = async () => {
    const base = new AirtableBase("MeshAR");
    base("MeshTypes")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          setSubmissions(records.length);
          setLoading(false);
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };

  const getTotalPageViews = async () => {
    const base = new AirtableBase("MeshAR");
    base("PageViews")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          setPageViews(records.length);
          setLoading(false);
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };

  return (
    <Box minH={"50vh"}>
      {loading ? (
        <Box
          w="100%"
          justifyContent="center"
          alignItems="center"
          alignContent={"center"}
          display="block"
        >
          <Text textAlign="center">
            Hold your horses, we are loading the data...
          </Text>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            display="block"
            margin="40px auto"
          />
        </Box>
      ) : (
        submissions && (
          <>
            <StatGroup
              border="1px solid #cccccc"
              borderRadius="md"
              padding="5"
              display="inline-block"
              margin="10px"
              bgColor="beige"
            >
              <Stat>
                <StatLabel fontSize="1.5em">Total Submissions</StatLabel>
                <StatNumber fontSize="4.0em">{submissions}</StatNumber>
              </Stat>
            </StatGroup>

            <StatGroup
              border="1px solid #cccccc"
              borderRadius="md"
              padding="5"
              display="inline-block"
              margin="10px"
              bgColor="beige"
            >
              <Stat>
                <StatLabel fontSize="1.5em">Total Page Views</StatLabel>
                <StatNumber fontSize="4.0em">{pageViews}</StatNumber>
              </Stat>
            </StatGroup>
          </>
        )
      )}
    </Box>
  );
};

export default MeshARDashboard;

import axios from "axios";
import { getUsersJWT } from "@impact-theory/app-auth";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

const axiosInstanceWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
});

axiosInstance.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      getUsersJWT()
        .then((jwt) => {
          resolve({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${jwt}`,
            },
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  (error) => Promise.reject(error)
);

export const api = {
  createTournament: (data) =>
    axiosInstance.post("api/admin/createTournament", data),
  getTournaments: (page, size) =>
    axiosInstance.get(`api/admin/tournaments?page=${page}&size=${size}`),
  startTournament: (tournamentId) =>
    axiosInstance.get(`api/admin/startTournament?tournamentId=${tournamentId}`),
  stopTournament: (tournamentId) =>
    axiosInstance.get(`api/admin/stopTournament?tournamentId=${tournamentId}`),
};

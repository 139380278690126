const AirtableBase = () => {
  const Airtable = require("airtable");
  Airtable.configure({
    endpointUrl: "https://api.airtable.com",
    // eslint-disable-next-line no-undef
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  });
  // eslint-disable-next-line no-undef
  const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);
  return base;
};

export default AirtableBase;
